










































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import SelectStatusLogisticSession from '@/components/molecules/select/selectStatusLogisticSession.vue'
import { userConnectedCanAccess } from '@/composables/UserGrant'
import { TrainingOfficerView } from '@/api/enums/trainingOfficerView'
import { mapActions, mapGetters } from 'vuex'
import ListTextFilter from '@/components/base/ListTextFilter.vue'
import ListSelectFilter from '@/components/base/ListSelectFilter.vue'
import DateField from '@/components/base/DateField.vue'
import { itemsStatusLogisticSession } from '@/config-constantes'
import DateFieldFilter from '@/components/base/DateFieldFilter.vue'
import { useDate } from '@/composables/date'
import { TrainingSessionTrainingOfficerView } from '@/api/interfaces/trainingSessionTrainingOfficerView'
import { TrainingLocation } from '@/api/interfaces/traininglocation'

export default defineComponent({
  name: 'dataTableTrainingOfficer',
  components: {
    DateFieldFilter,
    DateField,
    ListTextFilter,
    ListSelectFilter,
    SelectStatusLogisticSession,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    filtersHeaders: {
      type: Object,
      required: true,
    },
    showInitials: {
      type: Boolean,
      required: false,
      default: false,
    },
    monthSelected: {
      type: String,
      required: false,
    },
  },
  methods: {
    useDate,
    ...mapActions('trainingOfficer', {
      updateLogisticSession: 'updateLogisticSession',
      updateNeedTraining: 'updateNeedTraining',
      updateCommentaryTrainingOfficer: 'updateCommentaryTrainingOfficer',
    }),
    getNbPax (trainingSessions: Array<TrainingSessionTrainingOfficerView>) {
      let nbPax = 0

      trainingSessions.forEach((trainingSession: TrainingSessionTrainingOfficerView) => {
        // nbPax += trainingSession.numberTraineesRegistered
        nbPax += trainingSession.needTrainings.length
      })

      return nbPax
    },
    async updateLogisticSessionProperty (id: string, property: string, value: any) {
      await this.updateLogisticSession({ id, body: { [property]: value } })
    },
    async updateNeedTrainingProperty (id: string, property: string, value: any) {
      await this.updateNeedTraining({ id, body: { [property]: value } })
    },
    async updatePropertyCommentaryTrainingOfficer (id: string, commentary: string) {
      await this.updateCommentaryTrainingOfficer({ id, body: { commentary } })
    },
  },
  computed: {
    ...mapGetters('trainingType', {
      trainingTypesList: 'getListActiveItems',
    }),
    itemsStatusLogisticSession () {
      return itemsStatusLogisticSession
    },
    canEditLogisticSession (): boolean {
      return userConnectedCanAccess('LogisticSession Edit')
    },
    getHeaders (): Array<any> {
      const headers = []

      if (this.showInitials) {
        headers.push({
          text: this.$t('training_officers.list.initials'),
          value: 'initials',
          align: 'center',
        })
      }

      headers.push(
        {
          text: this.$t('training_officers.list.actions'),
          value: 'actions',
          align: 'center',
        },
        {
          text: this.$t('training_officers.list.status'),
          value: 'status',
          width: '200px',
          align: 'center',
        },
        {
          text: this.$t('training_officers.list.date'),
          value: 'startAt',
          width: '150px',
        },
        {
          text: this.$t('training_officers.list.city'),
          value: 'city',
          width: '140px',
        },
      )

      if (this.view === TrainingOfficerView.DRIVER_PROGRAM) {
        headers.push(
          {
            text: this.$t('training_officers.list.commentaries'),
            value: 'commentary',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '250px',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoHousing',
            width: '300px',
          },
          {
            text: this.$t('training_officers.list.schedules'),
            value: 'schedules',
            width: '120px',
          },
          {
            text: this.$t('training_officers.list.need_trainings_names'),
            value: 'needTrainingsNames',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.need_trainings_phones'),
            value: 'needTrainingsPhones',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.need_trainings_emails'),
            value: 'needTrainingsEmails',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.commentary_vehicle'),
            value: 'commentaryVehicle',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.convocation'),
            value: 'convocation',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.folder_vehicle'),
            value: 'folderVehicle',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.sms_and_mail'),
            value: 'smsAndMail',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.mail_concession'),
            value: 'mailConcession',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.sms_client'),
            value: 'smsClient',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.envoi_docs'),
            value: 'folderPostTrainingSession',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.report'),
            value: 'report',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.e-learning'),
            value: 'eLearning',
            width: '200px',
          },
        )
      } else if (this.view === TrainingOfficerView.OTHERS) {
        headers.push(
          {
            text: this.$t('training_officers.list.commentaries'),
            value: 'commentary',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.client'),
            value: 'client',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.training_type'),
            value: 'trainingType',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '250px',
          },
          {
            text: this.$t('training_officers.list.circuit'),
            value: 'commentaryTrainingLocation',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.nb_pax'),
            value: 'nbPax',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoHousing',
            width: '300px',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.need_trainings_names'),
            value: 'needTrainingsNames',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.convention'),
            value: 'convention',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.convocation_others'),
            value: 'convocation',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.folder_vehicle_others'),
            value: 'folderVehicle',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.folder_post_training_session'),
            value: 'folderPostTrainingSession',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.report_audit'),
            value: 'report',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.e-learning'),
            value: 'eLearning',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.billing'),
            value: 'billing',
            width: '200px',
          },
        )
      } else if (this.view === TrainingOfficerView.SUPPLIER_RELATIONSHIP) {
        headers.push(
          {
            text: this.$t('training_officers.list.training_type'),
            value: 'trainingType',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '250px',
          },
          {
            text: this.$t('training_officers.list.commentary_training_location'),
            value: 'commentaryTrainingLocation',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoHousing',
            width: '300px',
          },
          {
            text: this.$t('training_officers.list.commentary_info_housing'),
            value: 'commentaryHousing',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.commentary_caterer'),
            value: 'commentaryCaterer',
            width: '200px',
          },
          {
            text: this.$t('training_officers.list.commentary_supplier_relationship'),
            value: 'commentarySupplierRelationshipOfficer',
            width: '200px',
          },
        )
      }

      return headers
    },
  },
})
