var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 my-3 odd-background-lines",attrs:{"loading":_vm.isLoading,"headers":_vm.getHeaders,"items":_vm.items,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"80vh","loading-text":"","no-data-text":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.initials",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(item.trainingOfficer.user.initials)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditLogisticSession)?_c('router-link',{attrs:{"to":{
        name: 'LogisticSession Edit',
        params: { idLogisticSession: item.id.toString() },
      }}},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}},{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSession,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {status: $event}))}},model:{value:(_vm.filtersHeaders.status),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "status", $$v)},expression:"filtersHeaders.status"}})],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session',{attrs:{"label":_vm.$t('training_officers.list.status'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'status', $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.startAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startAt))+" ")]}},{key:"header.startAt",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date'),"min":_vm.monthSelected,"max":_vm.useDate().getLastMonthDayOfDate(_vm.monthSelected),"date-to-show":_vm.monthSelected,"range":""},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: null}))}},model:{value:(_vm.filtersHeaders.periodDays),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "periodDays", $$v)},expression:"filtersHeaders.periodDays"}})]}},{key:"header.commentary",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentary: $event}))}},model:{value:(_vm.filtersHeaders.commentary),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentary", $$v)},expression:"filtersHeaders.commentary"}})],1)]}},{key:"item.commentary",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentary', item.commentary)}},model:{value:(item.commentary),callback:function ($$v) {_vm.$set(item, "commentary", $$v)},expression:"item.commentary"}})]}},{key:"item.infoHousing",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'infoHousing', item.infoHousing)}},model:{value:(item.infoHousing),callback:function ($$v) {_vm.$set(item, "infoHousing", $$v)},expression:"item.infoHousing"}})]}},{key:"header.infoHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {infoHousing: $event}))}},model:{value:(_vm.filtersHeaders.infoHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "infoHousing", $$v)},expression:"filtersHeaders.infoHousing"}})],1)]}},{key:"item.commentaryHousing",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryHousing', item.commentaryHousing)}},model:{value:(item.commentaryHousing),callback:function ($$v) {_vm.$set(item, "commentaryHousing", $$v)},expression:"item.commentaryHousing"}})]}},{key:"header.commentaryHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryHousing: $event}))}},model:{value:(_vm.filtersHeaders.commentaryHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryHousing", $$v)},expression:"filtersHeaders.commentaryHousing"}})],1)]}},{key:"item.caterer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'caterer', item.caterer)}},model:{value:(item.caterer),callback:function ($$v) {_vm.$set(item, "caterer", $$v)},expression:"item.caterer"}})]}},{key:"header.caterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {caterer: $event}))}},model:{value:(_vm.filtersHeaders.caterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "caterer", $$v)},expression:"filtersHeaders.caterer"}})]}},{key:"item.commentaryCaterer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryCaterer', item.commentaryCaterer)}},model:{value:(item.commentaryCaterer),callback:function ($$v) {_vm.$set(item, "commentaryCaterer", $$v)},expression:"item.commentaryCaterer"}})]}},{key:"header.commentaryCaterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryCaterer: $event}))}},model:{value:(_vm.filtersHeaders.commentaryCaterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryCaterer", $$v)},expression:"filtersHeaders.commentaryCaterer"}})]}},{key:"item.commentaryTrainingLocation",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryTrainingLocation', item.commentaryTrainingLocation)}},model:{value:(item.commentaryTrainingLocation),callback:function ($$v) {_vm.$set(item, "commentaryTrainingLocation", $$v)},expression:"item.commentaryTrainingLocation"}})]}},{key:"header.commentaryTrainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryTrainingLocation: $event}))}},model:{value:(_vm.filtersHeaders.commentaryTrainingLocation),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryTrainingLocation", $$v)},expression:"filtersHeaders.commentaryTrainingLocation"}})]}},{key:"item.commentarySupplierRelationshipOfficer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentarySupplierRelationshipOfficer', item.commentarySupplierRelationshipOfficer)}},model:{value:(item.commentarySupplierRelationshipOfficer),callback:function ($$v) {_vm.$set(item, "commentarySupplierRelationshipOfficer", $$v)},expression:"item.commentarySupplierRelationshipOfficer"}})]}},{key:"header.commentarySupplierRelationshipOfficer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentarySupplierRelationshipOfficer: $event}))}},model:{value:(_vm.filtersHeaders.commentarySupplierRelationshipOfficer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentarySupplierRelationshipOfficer", $$v)},expression:"filtersHeaders.commentarySupplierRelationshipOfficer"}})]}},{key:"item.commentaryVehicle",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryVehicle', item.commentaryVehicle)}},model:{value:(item.commentaryVehicle),callback:function ($$v) {_vm.$set(item, "commentaryVehicle", $$v)},expression:"item.commentaryVehicle"}})]}},{key:"header.commentaryVehicle",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryVehicle: $event}))}},model:{value:(_vm.filtersHeaders.commentaryVehicle),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryVehicle", $$v)},expression:"filtersHeaders.commentaryVehicle"}})]}},{key:"item.report",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(item.report.id, item.report.commentary)}},model:{value:(item.report.commentary),callback:function ($$v) {_vm.$set(item.report, "commentary", $$v)},expression:"item.report.commentary"}})]}},{key:"item.eLearning",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(item.eLearning.id, item.eLearning.commentary)}},model:{value:(item.eLearning.commentary),callback:function ($$v) {_vm.$set(item.eLearning, "commentary", $$v)},expression:"item.eLearning.commentary"}})]}},{key:"item.billing",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(item.billing.id, item.billing.commentary)}},model:{value:(item.billing.commentary),callback:function ($$v) {_vm.$set(item.billing, "commentary", $$v)},expression:"item.billing.commentary"}})]}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingLocation.city)+" ")]}},{key:"header.city",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.city')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.city': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.city']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.city', $$v)},expression:"filtersHeaders['trainingLocation.city']"}})]}},{key:"item.trainingLocation",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingLocation.name)+" "+_vm._s(item.trainingLocation.address)+" "+_vm._s(item.trainingLocation.zipCode)+" "+_vm._s(item.trainingLocation.city)+" ")]}},{key:"header.trainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.training_location')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.text': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.text']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.text', $$v)},expression:"filtersHeaders['trainingLocation.text']"}})]}},{key:"item.trainers",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainers),function(trainer,indexTrainer){return _c('v-chip',{key:indexTrainer,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainer.lastName)+" "+_vm._s(trainer.firstName)+" ")])})}},{key:"header.trainers",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainers.name': $event}))}},model:{value:(_vm.filtersHeaders['trainers.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainers.name', $$v)},expression:"filtersHeaders['trainers.name']"}})]}},{key:"item.schedules",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(_vm._f("formatHour")(trainingSession.startAt))+" - "+_vm._s(_vm._f("formatHour")(trainingSession.endAt))+" ")])})}},{key:"item.needTrainingsNames",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.lastName)+" "+_vm._s(needTraining.trainee.firstName)+" ")])}),0)])})}},{key:"header.needTrainingsNames",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.name']"}})]}},{key:"item.needTrainingsPhones",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div',{staticClass:"height-client-trainee"}):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.phoneNumber)+" "+_vm._s(needTraining.trainee.phoneNumber && needTraining.trainee.mobilePhone ? ' / ' : '')+" "+_vm._s(needTraining.trainee.mobilePhone)+" ")])}),0)])})}},{key:"header.needTrainingsPhones",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.phone')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.phones': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.phones']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.phones', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.phones']"}})]}},{key:"item.needTrainingsEmails",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.email)+" ")])}),0)])})}},{key:"header.needTrainingsEmails",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.email')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.email': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.email']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.email', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.email']"}})],1)]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return _vm._l((item.clients),function(client,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(client)+" ")])})}},{key:"header.client",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.trainingType",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingTypes),function(trainingType,index){return _c('v-chip',{key:index,attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainingType)+" ")])})}},{key:"header.trainingType",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.training_type'),"items":_vm.trainingTypesList,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.id; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.trainingType.id': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.trainingType.id']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.trainingType.id', $$v)},expression:"filtersHeaders['trainingSessions.trainingType.id']"}})],1)]}},{key:"item.nbPax",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNbPax(item.trainingSessions))+" ")]}},{key:"item.convention",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendConventionAt', $event)}},model:{value:(needTraining.sendConventionAt),callback:function ($$v) {_vm.$set(needTraining, "sendConventionAt", $$v)},expression:"needTraining.sendConventionAt"}})],1)}),0)])})}},{key:"header.convention",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendConventionAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendConventionAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendConventionAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendConventionAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendConventionAt']"}})],1)]}},{key:"item.convocation",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendConvocationAt', $event)}},model:{value:(needTraining.sendConvocationAt),callback:function ($$v) {_vm.$set(needTraining, "sendConvocationAt", $$v)},expression:"needTraining.sendConvocationAt"}})],1)}),0)])})}},{key:"header.convocation",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendConvocationAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendConvocationAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendConvocationAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendConvocationAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendConvocationAt']"}})],1)]}},{key:"item.folderVehicle",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendVehicleFolderAt', $event)}},model:{value:(needTraining.sendVehicleFolderAt),callback:function ($$v) {_vm.$set(needTraining, "sendVehicleFolderAt", $$v)},expression:"needTraining.sendVehicleFolderAt"}})],1)}),0)])})}},{key:"header.folderVehicle",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendVehicleFolderAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendVehicleFolderAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendVehicleFolderAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendVehicleFolderAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendVehicleFolderAt']"}})],1)]}},{key:"item.smsAndMail",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendSmsAndMailCLientAt', $event)}},model:{value:(needTraining.sendSmsAndMailCLientAt),callback:function ($$v) {_vm.$set(needTraining, "sendSmsAndMailCLientAt", $$v)},expression:"needTraining.sendSmsAndMailCLientAt"}})],1)}),0)])})}},{key:"header.smsAndMail",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendSmsAndMailCLientAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendSmsAndMailCLientAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendSmsAndMailCLientAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendSmsAndMailCLientAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendSmsAndMailCLientAt']"}})],1)]}},{key:"item.mailConcession",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendMailCarDealerAt', $event)}},model:{value:(needTraining.sendMailCarDealerAt),callback:function ($$v) {_vm.$set(needTraining, "sendMailCarDealerAt", $$v)},expression:"needTraining.sendMailCarDealerAt"}})],1)}),0)])})}},{key:"header.mailConcession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendMailCarDealerAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendMailCarDealerAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendMailCarDealerAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendMailCarDealerAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendMailCarDealerAt']"}})],1)]}},{key:"item.smsClient",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendSmsAt', $event)}},model:{value:(needTraining.sendSmsAt),callback:function ($$v) {_vm.$set(needTraining, "sendSmsAt", $$v)},expression:"needTraining.sendSmsAt"}})],1)}),0)])})}},{key:"header.smsClient",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendSmsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendSmsAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendSmsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendSmsAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendSmsAt']"}})],1)]}},{key:"item.folderPostTrainingSession",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining},[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","text-field-class":indexNeedTraining != (trainingSession.needTrainings.length - 1) ? 'mb-2' : ''},on:{"validate":function($event){return _vm.updateNeedTrainingProperty(needTraining.id, 'sendPostTrainingDocumentsAt', $event)}},model:{value:(needTraining.sendPostTrainingDocumentsAt),callback:function ($$v) {_vm.$set(needTraining, "sendPostTrainingDocumentsAt", $$v)},expression:"needTraining.sendPostTrainingDocumentsAt"}})],1)}),0)])})}},{key:"header.folderPostTrainingSession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendPostTrainingDocumentsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.sendPostTrainingDocumentsAt': null}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.sendPostTrainingDocumentsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.sendPostTrainingDocumentsAt', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.sendPostTrainingDocumentsAt']"}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }